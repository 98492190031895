import React from 'react';
import { useStaticQuery, StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components';

const StyledImg = styled(GatsbyImage)`
  display: block;
  transition: transform 1s;
`

const Wrapper = styled.figure`
  margin: 0;
  overflow: hidden;

  &:hover {
    div {
      transform: scale(1.1);
    }
  }
`;

const WrappedImg = props => (<Wrapper><StyledImg {...props} /></Wrapper>)

export default function ImageZoom({ image, alt }) {
  return (
    <>
      <WrappedImg image={image} alt={alt} />
    </>
  );
}